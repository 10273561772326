/* Global styles */
.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 2rem;
}

.container h1 {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 2rem;
  max-width: 100%;
  word-break: break-all;
}

.content {
  padding-top: 100px;
}

.loading-text {
  color: aliceblue;
  font-size: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

/* Icons */
.icon {
  margin-inline: 1rem;
  font-size: 5rem;
  color: white;
}

.icon:hover {
  transform: scale(1.2);
  transition: transform 0.2s ease-in-out;
  color: rgb(145, 255, 145);
}

.copy-icon {
  margin-left: 0.5rem;
  color: white;
  transition: color 0.2s ease-in-out;
}

.copied-animation {
  color: aliceblue;
}

.copy-icon:hover {
  color: rgb(145, 255, 145);
}

/* Keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Image container */
.image-container {
  position: relative;
}

.image-frog {
  width: 100%;
}

.overlay-gif {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Typography */
h1,
p {
  color: aliceblue;
}

.one {
  font-size: 38px;
}

.two {
  font-size: 84px;
}

/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: white;
  padding: 20px;
  z-index: 999;
}

.navbar-left {
  font-size: 1.5rem;
}

/* SadFrog color */
.sadFrog {
  color: rgb(145, 255, 145);
}
